// Layout.js
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import CookieConsent from "react-cookie-consent";

const Layout = () => {
  return (
    <>
      <Header />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        enableDeclineButton
        cookieName="myWebsiteCookieConsent"
        style={{ background: "#000", color: "#fff", alignItems: "center" }}
        buttonStyle={{
          color: "#fff",
          background: "#4CAF50",
          fontSize: "14px",
          padding: ".5rem 1rem",
          borderRadius: "5px"
        }}
        declineButtonStyle={{
          color: "#fff",
          background: "#f44336",
          fontSize: "14px",
          padding: ".5rem 1rem",
          borderRadius: "5px"
        }}
        expires={365}
        onAccept={() => {
          console.log("User accepted the cookie policy");
        }}
        onDecline={() => {
          console.log("User declined the cookie policy");
        }}
      >
        <p>
          Minecraft Education brings the popular game Minecraft into classrooms
          as a powerful tool for learning. It uses game-based learning to
          inspire creativity, teamwork, and problem-solving skills in an
          immersive, limitless world.
        </p>
        <p>
          Discover more and start learning with free training{" "}
          <a href="https://aka.ms/MTA" target="_blank" rel="noreferrer">
            Learn more
          </a>
        </p>
      </CookieConsent>
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
