import { AiOutlineCopyright } from "react-icons/ai";
import { Link } from "react-router-dom";
import EULogo from "../../assets/images/EULogo.png";
import { Box, Container } from "@mui/material";

const Footer = () => {
  return (
    <footer>
      <Box className="content">
        <span>
          <AiOutlineCopyright /> EU Code Week 2024 - NOT AN OFFICIAL MINECRAFT
          EDUCATION PRODUCT
        </span>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <img src={EULogo} alt="Logo European Union" />
      </Box>
    </footer>
  );
};

export default Footer;
