import { Fragment } from "react";
import banner from "../assets/images/bannerDreamSpace.png";
import { Breadcrumb, DreamSpaceBox, DreamSpacePanel } from "./UI/ContentBlocks";
import { DreamSpaceContent } from "./websiteContent";
import { Container } from "@mui/material";

const DreamSpace = () => {
  return (
    <Fragment>
      <img src={banner} alt="Generation AI" className="bannerImage" />
      <Container fixed>
        <div className="mainBody">
          <Breadcrumb page="Teacher Guides and Resources" />
          <section>
            <DreamSpaceBox
              title={DreamSpaceContent.title}
              content={DreamSpaceContent.content}
            />
          </section>
          <section className="bgPink">
            <DreamSpacePanel
              title={DreamSpaceContent.teacherPackage.title}
              content={DreamSpaceContent.teacherPackage.content}
            />
            <DreamSpacePanel
              title={DreamSpaceContent.careerGuidance.title}
              content={DreamSpaceContent.careerGuidance.content}
            />
          </section>
        </div>
      </Container>
    </Fragment>
  );
};

export default DreamSpace;
