import { Fragment } from "react";
import banner from "../assets/images/bannerFullVersion.png";
import { MainTitle } from "./UI/Title";
import { Button, Container, Grid2 } from "@mui/material";
import download1 from "../assets/images/download1.png";
import { Breadcrumb } from "./UI/ContentBlocks";
import { HashLink } from "react-router-hash-link";

const FullVersion = () => {
  return (
    <Fragment>
      <img src={banner} alt="Generation AI" className="bannerImage" />
      <Container fixed>
        <div className="mainBody">
          <Breadcrumb page="Getting Started" />
          <section>
            <Grid2 container justifyContent={"center"}>
              <Grid2 size={{ xs: 12, md: 5 }}>
                <img
                  src={download1}
                  alt="Good News"
                  style={{ width: "100%" }}
                />
              </Grid2>
            </Grid2>
            <MainTitle
              title="GOOD NEWS! YOU HAVE THE FULL VERSION OF MINECRAFT EDUCATION!"
              paragraph="As your school has a fully licensed version of Minecraft Education, you have access to the entire range of teaching and learning content – including exciting new worlds and learning experiences focused upon developing coding skills and AI literacy!"
            />
          </section>
          <section className="bgPink">
            <MainTitle
              title="TAKE ME TO THE GOOD STUFF!"
              paragraph="Click on the button below to access a range of teacher guides and resources, sign up for a live Minecraft Education event, and learn about the EU Code Week Build Challenge!"
            />
            <Grid2 container justifyContent={"center"}>
              <Grid2 size={{ xs: 12 }}>
                <HashLink to={"/#skillsHash"} className="btnLongCenter">
                  <Button variant="contained">Let's Go!</Button>
                </HashLink>
              </Grid2>
            </Grid2>
          </section>
        </div>
      </Container>
    </Fragment>
  );
};
export default FullVersion;
