import "./App.scss";
import ScrollToTop from "./components/common/ScrollToTop";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/common/Layout";
import Home from "./components/Home";
// import Register from "./components/Register";
import Download from "./components/Download";
import DownloadMinecraft from "./components/DownloadMinecraft";
import FullVersion from "./components/FullVersion";
import TrialVersion from "./components/TrialVersion";
import DownloadAgain from "./components/DownloadAgain";
import TeacherGuide from "./components/TeacherGuide";
import DreamSpace from "./components/DreamSpace";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route path="/sign-up" element={<Register />} /> */}
          <Route path="/download" element={<Download />} />
          <Route path="/download-minecraft" element={<DownloadMinecraft />} />
          <Route path="/full-version" element={<FullVersion />} />
          <Route path="/trial-version" element={<TrialVersion />} />
          <Route path="/download-again" element={<DownloadAgain />} />
          <Route path="/teacher-guide" element={<TeacherGuide />} />
          <Route path="/dream-space" element={<DreamSpace />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path="/privacy_policy" element={<PrivacyPolicy />} /> */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
