import { Box, Button, Container, Grid2 } from "@mui/material";
import { LinkArrow } from "./svgIcons";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { MainTitle } from "./Title";

export const Skills = (props) => {
  return (
    <Grid2
      container
      spacing={2}
      justifyContent={"center"}
      className="skillGrid"
    >
      {props.content.map((item, index) => (
        <Grid2 size={{ xs: 12, md: 6 }} key={index}>
          <Box
            className={`skillBox ${index === 0 ? "first" : ""}`}
            style={{ backgroundImage: `url(${item.image})` }}
          >
            <Box className="content">
              <Link
                to={item.link}
                target={item.link.startsWith("https") && "_blank"}
              />
              <h5>{item.title}</h5>
              <div className="paragraphs">
                {item.paragraph.map((item, index) => (
                  <p key={index}>{item}</p>
                ))}
              </div>
              <LinkArrow />
            </Box>
          </Box>
        </Grid2>
      ))}
    </Grid2>
  );
};

export const Breadcrumb = (props) => {
  return (
    <Box className="breadCrumb">
      <Container fixed>
        <Grid2 container justifyContent={"center"}>
          <Grid2 size={{ xs: 12 }}>
            <ul>
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>{props.page}</li>
            </ul>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
};

export const DreamSpaceBox = (props) => {
  return (
    <Fragment>
      <MainTitle title={props.title.title} paragraph={props.title.paragraph} />
      <Grid2 container justifyContent={"center"} spacing={3}>
        {props.content.map((item, index) => (
          <Grid2 size={{ xs: 12, md: 6, lg: 4 }} key={index}>
            <Box className="dreamBox" style={{ backgroundColor: item.color }}>
              <h5>{item.title}</h5>
              <Link to={item.link} target="_blank">
                <LinkArrow />
              </Link>
            </Box>
          </Grid2>
        ))}
      </Grid2>
    </Fragment>
  );
};

export const DreamSpacePanel = (props) => {
  return (
    <Fragment>
      <MainTitle title={props.title.title} paragraph={props.title.paragraph} />
      <Grid2 container justifyContent={"center"} spacing={3}>
        {props.content.map((item, index) => (
          <Grid2 size={{ xs: 12 }} key={index}>
            <Box className="dreamPanel" style={{ backgroundColor: item.color }}>
              <h5>{item.title}</h5>
              <Link to={item.link} target="_blank">
                <Button
                  variant="contained"
                  style={{ backgroundColor: item.btnColor }}
                >
                  Open Link
                </Button>
              </Link>
            </Box>
          </Grid2>
        ))}
      </Grid2>
    </Fragment>
  );
};
