// Home

import skill1 from "../assets/images/skill1.png";
import skill2 from "../assets/images/skill2.png";
import skill3 from "../assets/images/skill3.png";
import skill4 from "../assets/images/skill4.png";
import skill5 from "../assets/images/skill5.png";
import skill6 from "../assets/images/skill6.png";
import { LinkArrow } from "./UI/svgIcons.jsx";

export const HomeContent = {
  title: {
    title: "LET’S GO!",
    paragraph:
      "Click on the tiles above to unlock fantastic teacher guides and resources. Sign up for a play-along, streamed directly to your classroom, or take part in the EU Code Week Build Challenge."
  },
  content: [
    {
      image: skill1,
      title: "Educator Prep Workshop",
      paragraph: [
        "Join Dream Space educators Michael Barrett and Neeve Hyland for an Educator Prep session, helping you get ready to take part in EU Code Week activities with Minecraft Education."
      ],
      link: "https://microsoft.bigmarker.com/minecrafteducation/EU-Code-Week-Minecraft-Education-Educator-101"
    },
    {
      image: skill2,
      title: "Play-Along – Hour of Code: Generation AI",
      paragraph: [
        "Join Dream Space educators Michael Barrett and Neeve Hyland as they guide your class on a journey through time to create innovative, AI-powered inventions in Minecraft Education. No Minecraft Education license is needed to participate in this session."
      ],
      link: "https://aka.ms/EUCODEAI"
    },
    {
      image: skill3,
      title: "Play-Along – Fantastic Fairgrounds",
      paragraph: [
        "Join Dream Space educators Michael Barrett and Neeve Hyland for an exciting journey into the world of AI! Explore how we can ensure responsible AI practices in the world around us while visiting a virtual fairground. No Minecraft Education license is needed to participate in this session."
      ],
      link: "https://aka.ms/EUCODEFAIR"
    },
    {
      image: skill4,
      title: "Play-Along – AI for Earth",
      paragraph: [
        "Join Dream Space educators Michael Barrett and Neeve Hyland as they guide you through the first step of your journey in the AI for Earth series where we explore how AI-powered tools can help with animal conservation. A Minecraft Education license is needed to participate in this session."
      ],
      link: "https://aka.ms/EUCODEEARTH"
    },
    {
      image: skill5,
      title:
        "Super Schools: The European Co-Mission - A Minecraft Education & Code Week Build Challenge",
      paragraph: [
        "Welcome to Super Schools: The European Co-Mission, a Minecraft Education build challenge designed to help students reimagine and shape the future of their schools and Europe. This challenge requires students to have a Minecraft Education license."
      ],
      link: "https://aka.ms/EUCODESCHOOL"
    },
    {
      image: skill6,
      title: "Access Teacher Guides and Resources",
      paragraph: [
        "Click on this tile to unlock fantastic guides and resources designed by and for educators, to help you get the most out of Minecraft Education.",
        "Dive in and have fun!"
      ],
      link: "/teacher-guide"
    }
  ]
};

export const DreamSpaceContent = {
  title: {
    title: "Dream Space TV",
    paragraph:
      "Dream Space TV is a comprehensive package of on-demand resources for use in primary, post-primary or youth group settings which guides young people on a learning journey focused on computational thinking, digital creativity and problem-solving. Our Dream Space TV resources are designed for off-the-shelf use by teachers and facilitators, with clear guidelines as to age, activity, and curricular links."
  },
  content: [
    {
      title: "Dream Space TV ByteSize",
      color: "rgba(207, 5, 59, 1)",
      link: "https://aka.ms/DSTVBytesize",
      icon: LinkArrow
    },
    {
      title: "Dream Space TV for Primary: STEAM series",
      color: "rgba(220, 5, 43, 1)",
      link: "https://forms.microsoft.com/pages/responsepage.aspx?id=v4j5cvGGr0GRqy180BHbRw1p_hdLj5ZJjeb27xtnZsBUNjFXUE4yRTRWNDg5SldWQ1ZYWlZPNVBPRi4u&route=shorturl",
      icon: LinkArrow
    },
    {
      title: "Dream Space TV: brAIn_waves for primary schools",
      color: "rgba(232, 83, 14, 1)",
      link: "https://aka.ms/DSTVbrAInWavesPrimary",
      icon: LinkArrow
    },
    {
      title: "Dream Space TV: Ireland’s Future is MINE",
      color: "rgba(243, 149, 0, 1)",
      link: "https://aka.ms/DSTVIFIM",
      icon: LinkArrow
    },
    {
      title: "Dream Space TV: Code Green series",
      color: "rgba(224, 48, 90, 1)",
      link: "https://aka.ms/DSTVCodeGreen",
      icon: LinkArrow
    },
    {
      title: "Dream Space TV for Post-Primary",
      color: "rgba(232, 19, 128, 1)",
      link: "https://aka.ms/DSTVPostPrimary",
      icon: LinkArrow
    },
    {
      title: "Dream Space TV: brAIn_waves for post-primary schools",
      color: "rgba(185, 11, 126, 1)",
      link: "https://aka.ms/DSTVbrAInWavesPostPrimary",
      icon: LinkArrow
    },
    {
      title: "Dream Space TV as Gaeilge",
      color: "rgba(148, 25, 127, 1)",
      link: "https://aka.ms/DSTVasGaeilge",
      icon: LinkArrow
    }
  ],
  teacherPackage: {
    title: {
      title: "Dream Space Teacher Packages",
      paragraph:
        "We believe in listening to teachers! Our aim is to provide them with access to the resources and the support they need to assist them in their schools. Our Dream Space Career Guidance resources have been developed to support guidance counsellors and students, with inputs from the STEM and Technology Industry, and to help demystify what key IT jobs entail, and to spark student curiosity in the huge range of career pathways available to them."
    },
    content: [
      {
        title: "Computational Thinking with micro:bit - for primary",
        color: "rgba(178, 212, 250, 1)",
        btnColor: "rgba(0, 100, 224, 1)",
        link: "https://aka.ms/DSTeacherCompT"
      },
      {
        title: "Gamifying STEAM with MakeCode Arcade - for primary ",
        color: "rgba(255, 208, 209, 1)",
        btnColor: "rgba(201, 46, 52, 1)",
        link: "https://aka.ms/DSTeacherArcadePrimary"
      },
      {
        title:
          "Coding Through Game Design with MakeCode Arcade - for post-primary",
        color: "rgba(255, 227, 200, 1)",
        btnColor: "rgba(204, 57, 26, 1)",
        link: "https://forms.office.com/pages/responsepage.aspx?id=v4j5cvGGr0GRqy180BHbRw1p_hdLj5ZJjeb27xtnZsBURDZCUDc2NVpBM0pHMk1XR1AxOEIwNVM5Ny4u&route=shorturl"
      },
      {
        title: "Programming with Python - for post-primary",
        color: "rgba(228, 216, 251, 1)",
        btnColor: "rgba(88, 52, 190, 1)",
        link: "https://forms.office.com/pages/responsepage.aspx?id=v4j5cvGGr0GRqy180BHbRw1p_hdLj5ZJjeb27xtnZsBURVhLUzRVWkVMTUFXUVBMN082MFFKUEtSWC4u&route=shorturl"
      }
    ]
  },
  careerGuidance: {
    title: {
      title: "Dream Space in Career Guidance",
      paragraph:
        "We believe in listening to teachers in the education system and providing access to resources and information that supports them based in their schools. Our Dream Space in Career Guidance resources have been developed to support career guidance counsellors and students with inputs from the STEM and technology industry to demystify what these jobs entail and spark curiosity in areas never considered."
    },
    content: [
      {
        title:
          "Dream Space Champion: STEAM Careers Resource for Career Classes",
        color: "rgba(178, 212, 250, 1)",
        btnColor: "rgba(0, 100, 224, 1)",
        link: "https://forms.office.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbRw1p_hdLj5ZJjeb27xtnZsBURVhCWklTQ09CSFFSSk5GOVM2Uk5aUlpESi4u"
      }
    ]
  }
};

export const PrivacyPolicyContent = {
  title: {
    title: "PRIVACY POLICY",
    date: "Last updated: October 11, 2024",
    paragraph: [
      "This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.",
      "We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy."
    ]
  },
  content: [
    {
      mainTitle: "Interpretation and Definitions"
    },
    {
      title: "Interpretation",
      paragraph: [
        "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural."
      ]
    },
    {
      title: "Definitions",
      paragraph: [
        "For the purposes of this Privacy Policy:",
        "Account: means account used by You to access our Service or parts of our Service.",
        'Company : (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ‘Terawe International’.',
        "Cookies : are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.",
        "Country:  refers to any country within the European Union that you are accessing the data from",
        "Device: means any device that can access the Service such as a computer, a cellphone or a digital tablet.",
        "Personal Data: is any information that relates to an identified or identifiable individual.",
        "Service: refers to the Website.",
        "Service Provider: means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. ",
        "Third-party Social Media Service: refers to any website or any social network website through which a User can reach the Service.",
        "Usage Data: refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).",
        "Website: refers to Minecraft Education for Code Week, a Terawe Website accessible from <a href='www.minecraftedu4codeweek.eu' taret='_blank'>www.minecraftedu4codeweek.eu</a> ",
        "You: means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable."
      ]
    },
    {
      mainTitle: "Collecting and Using Your Personal Data"
    },
    {
      title: "Types of Data Collected",
      subTitle: "Personal Data",
      paragraph: [
        "While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:"
      ],
      list: ["Email address", "First name and last name", "School Name"]
    },
    {
      subTitle: "Usage Data",
      paragraph: ["Usage Data is not collected when using the Service."]
    },
    {
      subTitle: "Tracking Technologies and Cookies",
      paragraph: [
        "We do not use Cookies and similar tracking technologies to track the activity on Our Service or store any information."
      ]
    },
    {
      subTitle: "Use of Your Personal Data",
      paragraph: [
        "The Company may use Personal Data for the following purposes:"
      ],
      list: [
        "To provide and maintain our Service: including to monitor the usage of our Service.",
        "For the performance of a contract:  the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.",
        "To contact You:  by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
        "To provide You: with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.",
        "To manage Your requests:  To attend and manage Your requests to Us.",
        "For other purposes:  We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience."
      ]
    },
    {
      mainTitle: "Sharing of Your Personal Data"
    },
    {
      paragraph: [
        "We may share Your personal information in the following situations:"
      ],
      list: [
        "With Service Providers:  We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You."
      ]
    },
    {
      mainTitle: "Retention of Your Personal Data"
    },
    {
      paragraph: [
        "The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy."
      ]
    },
    {
      mainTitle: "Transfer of Your Personal Data"
    },
    {
      paragraph: [
        "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located.",
        "Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.",
        "The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information."
      ]
    },
    {
      mainTitle: "Security of Your Personal Data"
    },
    {
      paragraph: [
        "The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security."
      ]
    },
    {
      mainTitle: "Deletion of Your Personal Data"
    },
    {
      paragraph: [
        "You can, at any time, request the deletion of your data by reaching out to us using the Contact information detailed below."
      ]
    },
    {
      mainTitle: "Children's Privacy"
    },
    {
      paragraph: [
        "Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.",
        "If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information."
      ]
    },
    {
      mainTitle: "Links to Other Websites"
    },
    {
      paragraph: [
        "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
        "We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services."
      ]
    },
    {
      mainTitle: "Changes to this Privacy Policy"
    },
    {
      paragraph: [
        "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
        "We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page."
      ]
    },
    {
      mainTitle: "Contact Us"
    },
    {
      title: "Interpretation",
      paragraph: [
        "If you have any questions about this Privacy Policy, You can contact us at <a href='mailto:edusup@terawe.com'>edusup@terawe.com</a> "
      ]
    }
  ]
};
