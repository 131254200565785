import { Fragment, useState } from "react";
import banner from "../assets/images/bannerDownload.png";
import { Breadcrumb } from "./UI/ContentBlocks";
import { MainTitle } from "./UI/Title";
import { Button, Container, Grid2, Typography } from "@mui/material";
import { FaCheck } from "react-icons/fa6";

import download1 from "../assets/images/download1.png";
import download2 from "../assets/images/download2.png";
import download3 from "../assets/images/download3.png";
import { Link } from "react-router-dom";

const screens = [download1, download2, download3];
const Download = () => {
  const [selectedScreen, setSelectedScreen] = useState("");
  const screenSelectHandler = (index) => {
    setSelectedScreen(index);
  };
  return (
    <Fragment>
      <img src={banner} alt="Generation AI" className="bannerImage" />
      <Container fixed>
        <div className="mainBody">
          <Breadcrumb page="Getting Started" />
          <section>
            <MainTitle
              title="WHAT VERSION OF MINECRAFT EDUCATION DOES MY SCHOOL HAVE?"
              paragraph="Good news! Many Schools in the EU already have a fully-licensed version of Minecraft Education! To find out if your school has a full license, sign in to Minecraft Education once you have installed it, and see which one of the three screens below appears!"
            />
            <Grid2 container justifyContent={"center"}>
              <Grid2 size={{ xs: 12 }}>
                <Typography align="center">
                  <b>Note:</b> Select the screen which most closely resembles
                  the one you see, and then click Next!
                </Typography>
                <ul className="downloadImages">
                  {screens.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => screenSelectHandler(index)}
                      className={index === selectedScreen ? "active" : ""}
                    >
                      <img src={item} alt="download 1" />
                      <FaCheck />
                    </li>
                  ))}
                </ul>
                <Link
                  to={
                    selectedScreen === 0
                      ? "/full-version"
                      : selectedScreen === 1
                      ? "/trial-version"
                      : "/download-again"
                  }
                  className="btnDownloaded"
                  onClick={(event) => {
                    if (selectedScreen === "") event.preventDefault();
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={selectedScreen === "" ? true : false}
                  >
                    Next
                  </Button>
                </Link>
              </Grid2>
            </Grid2>
          </section>
        </div>
      </Container>
    </Fragment>
  );
};

export default Download;
