import { Box, Grid2 } from "@mui/material";

export const MainTitle = (props) => {
  return (
    <Grid2 container spacing={2} justifyContent="center">
      <Grid2 size={{ xs: 12 }}>
        <Box className={`${props.className ? props.className : ""} mainTitle`}>
          <h2 dangerouslySetInnerHTML={{ __html: props.title }} />
          <p dangerouslySetInnerHTML={{ __html: props.paragraph }} />
        </Box>
      </Grid2>
    </Grid2>
  );
};
