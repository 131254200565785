import { forwardRef, Fragment, useRef, useState } from "react";
import banner from "../assets/images/bannerTrailVersion.png";
import { MainTitle } from "./UI/Title";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Slide,
  TextField,
  Typography
} from "@mui/material";
import download2 from "../assets/images/download2.png";
import { Link } from "react-router-dom";
import { Breadcrumb } from "./UI/ContentBlocks";
import { HashLink } from "react-router-hash-link";
import axios from "axios";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TrialVersion = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    email: false,
    emailError: "",
    message: false,
    messageError: ""
  });
  const [userData, setUserData] = useState({});
  const emailRef = useRef("");
  const messageRef = useRef("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const enquiryHandler = () => {
    if (userData?.message?.length > 0 && userData?.emailId?.length > 0) {
      setOpen(false);
    }
    var emailValidate = /^[a-zA-Z0-9._%+-]+@microsoft\.com$/;
    if (emailRef.current.value === "") {
      setError((prev) => ({
        ...prev,
        email: true,
        emailError: "Please Enter your Email"
      }));
    } else if (!emailValidate.test(emailRef.current.value)) {
      setError((prev) => ({
        ...prev,
        email: true,
        emailError: "Please Enter valid Microsoft Email ID"
      }));
    } else if (
      emailRef.current.value &&
      emailValidate.test(emailRef.current.value)
    ) {
      setError((prev) => ({
        ...prev,
        email: false,
        emailError: ""
      }));
      setUserData((prev) => ({
        ...prev,
        emailId: emailRef.current.value
      }));
    }
    switch (messageRef.current.value) {
      case "":
        setError((prev) => ({
          ...prev,
          message: true,
          messageError: "Please enter Name"
        }));
        break;
      default:
        setError((prev) => ({
          ...prev,
          message: false,
          messageError: ""
        }));
        setUserData((prev) => ({
          ...prev,
          message: messageRef.current.value
        }));
    }
    axios
      .post(
        "https://mcedu4eu-backend.azurewebsites.net/trial/form/send-email",
        {
          name: userData?.userName,
          school: userData?.school,
          email: userData?.emailId
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <Fragment>
      <img src={banner} alt="Generation AI" className="bannerImage" />
      <Container fixed>
        <div className="mainBody">
          <Breadcrumb page="Getting Started" />
          <section>
            <Grid2 container justifyContent={"center"}>
              <Grid2 size={{ xs: 12, md: 5 }}>
                <img
                  src={download2}
                  alt="Good News"
                  style={{ width: "100%" }}
                />
              </Grid2>
            </Grid2>
            <MainTitle
              title="GOOD NEWS! YOU HAVE A TRIAL EDITION OF MINECRAFT EDUCATION!"
              paragraph="Your school does not currently have a fully licensed version of Minecraft Education - but your Trail Edition gives you access to a range of teaching and learning resources designed to help your students develop coding skills and AI literacy!"
            />
            <Grid2 container justifyContent={"center"}>
              <Grid2 size={{ xs: 12 }}>
                <Typography align="center" mb={5}>
                  Want more? Click on to the “Enquire about Full Version” to
                  find out how your school can get a fully licensed version of
                  Minecraft Education.
                </Typography>
                <Link className="btnLongCenter">
                  <Button variant="outlined" onClick={handleClickOpen}>
                    Click here to Enquire about Full Version
                  </Button>
                </Link>
              </Grid2>
            </Grid2>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
              className="enquireForm"
            >
              <DialogTitle>ENQUIRE ABOUT MINECRAFT EDUCATION</DialogTitle>
              <DialogContent>
                <TextField
                  error={error.email}
                  helperText={error.emailError}
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  inputRef={emailRef}
                />
                <TextField
                  error={error.message}
                  helperText={error.messageError}
                  label="Message"
                  multiline
                  rows={4}
                  defaultValue="Yes! I would like to find out how my school can get a full license of Minecraft Education! Please send me the  details!"
                  fullWidth
                  inputRef={messageRef}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button variant="contained" onClick={enquiryHandler}>
                  Send
                </Button>
              </DialogActions>
            </Dialog>
          </section>
          <section className="bgPink">
            <MainTitle
              title="TAKE ME TO THE GOOD STUFF!"
              paragraph="Click on the button below to access a range of teacher guides and resources, sign up for a live Minecraft Education event, and learn about the EU Code Week Build Challenge!"
            />
            <Grid2 container justifyContent={"center"}>
              <Grid2 size={{ xs: 12 }}>
                <HashLink to={"/#skillsHash"} className="btnLongCenter">
                  <Button variant="contained">Let's Go!</Button>
                </HashLink>
              </Grid2>
            </Grid2>
          </section>
        </div>
      </Container>
    </Fragment>
  );
};
export default TrialVersion;
