import { Fragment } from "react";
import banner from "../assets/images/bannerTeacherGuide.png";
import { Breadcrumb } from "./UI/ContentBlocks";
import { MainTitle } from "./UI/Title";
import { Box, Button, Container, Grid2 } from "@mui/material";
import { Link } from "react-router-dom";

const TeacherGuide = () => {
  return (
    <Fragment>
      <img src={banner} alt="Generation AI" className="bannerImage" />
      <Container fixed>
        <div className="mainBody">
          <Breadcrumb page="Teacher Guides and Resources" />
          <section>
            <MainTitle
              title="Access Teacher Guides and Resources"
              paragraph="Explore the principles of Responsible AI and discover ways AI can help us solve a range of problems, with free Minecraft Education coding lessons and teaching & learning activities."
            />
            <Grid2 container justifyContent={"center"} spacing={3}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Box className="teacherPanel">
                  <h4>Explore Lesson Plans & Educator Resources</h4>
                  <p>
                    Minecraft Education provides over 200 hours of free Computer
                    Science content for all skill levels, with training
                    materials designed by and for educators. Coding has never
                    been more fun!
                  </p>
                  <Link
                    to={
                      "https://education.minecraft.net/en-us/resources/computer-science"
                    }
                    target="_blank"
                  >
                    <Button variant="contained">Explore</Button>
                  </Link>
                </Box>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Box className="teacherPanel orange">
                  <h4>Access Resources & Training</h4>
                  <p>
                    Our Professional Learning Program helps you to integrate
                    Minecraft Education into your teaching – regardless of your
                    coding or IT experience! Access free, on-demand training and
                    sign up today!
                  </p>
                  <Link
                    to={
                      "https://education.minecraft.net/en-us/resources/get-trained"
                    }
                    target="_blank"
                  >
                    <Button variant="contained">Explore</Button>
                  </Link>
                </Box>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Box className="teacherPanel red">
                  <h4>explore dream space</h4>
                  <p>
                    Microsoft Dream Space empowers educators to teach key
                    digital and 21st Century skills, with ready-to-use resources
                    like worksheets, tutorials and on-demand learning, to guide
                    students in computational thinking and creativity!
                  </p>
                  <Link to={"/dream-space"}>
                    <Button variant="contained">Explore</Button>
                  </Link>
                </Box>
              </Grid2>
            </Grid2>
          </section>
        </div>
      </Container>
    </Fragment>
  );
};

export default TeacherGuide;
