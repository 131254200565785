import { Box, Button, Container, Grid2 } from "@mui/material";
import { MainTitle } from "./UI/Title";
import banner from "../assets/images/bannerDownloadMinecraft.png";
import { Fragment } from "react";
import { Breadcrumb } from "./UI/ContentBlocks";
import { Link } from "react-router-dom";

const DownloadMinecraft = () => {
  return (
    <Fragment>
      <img src={banner} alt="Generation AI" className="bannerImage" />
      <Container fixed>
        <div className="mainBody">
          <Breadcrumb page="Getting Started" />
          <section>
            <MainTitle
              title={"HOW DO I DOWNLOAD OR OPEN MINECRAFT EDUCATION?"}
            />
            <Grid2 container justifyContent={"center"}>
              <Grid2 size={{ xs: 12, md: 7 }}>
                <Box className="stepsDownload">
                  <span>Step 1</span>
                  <p>
                    Click on the link below to download Minecraft Education
                    (this will open a new tab or window on your device)
                  </p>
                  <Link
                    to={
                      "https://education.minecraft.net/en-us/get-started/download"
                    }
                    target="_blank"
                  >
                    <Button variant="contained">
                      Download Minecraft Education
                    </Button>
                  </Link>
                </Box>
                <Box className="stepsDownload">
                  <span>Step 2</span>
                  <p>
                    Once the download is completed, install the application on
                    your device and click the Next button below.
                  </p>
                  <Link to={"/download"}>
                    <Button variant="contained">Next</Button>
                  </Link>
                </Box>
              </Grid2>
            </Grid2>
          </section>
        </div>
      </Container>
    </Fragment>
  );
};

export default DownloadMinecraft;
