import { Box } from "@mui/material";
import logo from "../../assets/images/logo.png";
import logoTerawe from "../../assets/images/logoTerawe.png";
import codeWeekLogo from "../../assets/images/codeWeekLogo.jpg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
// import { BiLogInCircle } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const Header = () => {
  const location = useLocation();
  const [image, setImage] = useState("");

  const updateImage = (path) => {
    switch (path) {
      case "/":
        return <></>;
      case "/privacy-policy":
        return (
          <Box className="mainMenu">
            <Link to={"/"}>
              <img src={logoTerawe} alt="minecraft logo" className="logo" />
            </Link>
          </Box>
        );
      default:
        return (
          <Box className="mainMenu">
            <Link to={"/"}>
              <img src={logo} alt="minecraft logo" className="logo" />
            </Link>
            <Link to={"https://codeweek.eu/"} target="_blank">
              <img src={codeWeekLogo} alt="minecraft logo" className="logo" />
            </Link>
          </Box>
        );
    }
  };

  useEffect(() => {
    const newImageSrc = updateImage(location.pathname); // Update image based on the path
    setImage(newImageSrc);
  }, [location]);
  return (
    <header>
      <Container fixed>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>{image}</Grid>
        </Grid>
      </Container>
    </header>
  );
};

export default Header;
