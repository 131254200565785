import { Container, Grid2 } from "@mui/material";
import { Fragment } from "react";
import { PrivacyPolicyContent } from "./websiteContent";

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <Container fixed>
        <div className="mainBody privacyPage">
          <section>
            <Grid2 container>
              <Grid2 size={{ xs: 12 }} className="privacyPolicy">
                <h1>{PrivacyPolicyContent.title.title}</h1>
                <p>
                  <i>{PrivacyPolicyContent.title.date}</i>
                </p>
                {PrivacyPolicyContent.title.paragraph.map((item, index) => (
                  <p key={index.toString()}>{item}</p>
                ))}
                {PrivacyPolicyContent.content.map((item, index) => (
                  <div key={index.toString()}>
                    {item.mainTitle && <h4>{item.mainTitle}</h4>}
                    {item.title && <h5>{item.title}</h5>}
                    {item.paragraph &&
                      item.paragraph.map((item, index) => (
                        <p
                          key={index.toString()}
                          dangerouslySetInnerHTML={{ __html: item }}
                        />
                      ))}
                    {item.list && (
                      <ul>
                        {item.list.map((item, index) => (
                          <li key={index.toString()}>{item}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </Grid2>
            </Grid2>
          </section>
        </div>
      </Container>
    </Fragment>
  );
};

export default PrivacyPolicy;
