import { Fragment } from "react";
import banner from "../assets/images/homeBanner.png";
import { MainTitle } from "./UI/Title";
import { HomeContent } from "./websiteContent";
import { Skills } from "./UI/ContentBlocks";
import { Box, Button, Container, Grid, Typography } from "@mui/material";

import codeWeek from "../assets/images/robotBalloons.png";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <Fragment>
      <img src={banner} alt="ai adventures" className="bannerImage" />
      <Container fixed>
        <div className="mainBody">
          <section>
            {/* <MainTitle title="BUILDING SKILLS IN DIGITAL AND AI LITERACY WITH EU CODE WEEK" /> */}
            <Grid container>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Box className="registerJourney">
                    <div className="imageContainer">
                      <img src={codeWeek} alt="cccc" />
                    </div>
                    <Box className="content">
                      <h5>
                        BUILDING SKILLS IN DIGITAL AND AI LITERACY WITH EU CODE
                        WEEK
                      </h5>
                      <Link to={"/download-minecraft"}>
                        <Button variant="contained" size="large">
                          Start Here
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                {/* <Grid item xs={12}>
                  <p className="downloadME">
                    Not ready to Sign up ? Go directly to this link to{" "}
                    <Link
                      to={
                        "https://education.minecraft.net/en-us/get-started/download"
                      }
                      target="_blank"
                    >
                      Download Minecraft Education
                    </Link>
                  </p>
                </Grid> */}
              </Grid>
            </Grid>
            <div id="skillsHash">
              <MainTitle title={HomeContent.title.title} />
              <Skills content={HomeContent.content} />
              <Typography align="center" marginTop={4}>
                {HomeContent.title.paragraph}
              </Typography>
            </div>
          </section>
          {/* <a href='https://education.minecraft.net' target='_blank'></a> */}
          <section className="bgPink">
            <Container fixed>
              <Grid container justifyContent={"center"}>
                <MainTitle
                  title={"WHAT IS MINECRAFT EDUCATION?"}
                  paragraph="Visit the official <a href='https://education.minecraft.net' target='_blank'>Minecraft Education</a> website to find out more!"
                  className="bigParagraph"
                />
              </Grid>
            </Container>
          </section>
        </div>
      </Container>
    </Fragment>
  );
};

export default Home;
