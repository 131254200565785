export const LinkArrow = () => {
  return (
    <svg
      width="66"
      height="65"
      viewBox="0 0 66 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.249 0L61.3949 16.25V48.75L33.249 65L5.1032 48.75V16.25L33.249 0Z"
        fill="white"
      />
      <path
        d="M47.8097 33.0607C48.3955 32.4749 48.3955 31.5251 47.8097 30.9393L38.2637 21.3934C37.678 20.8076 36.7282 20.8076 36.1424 21.3934C35.5566 21.9792 35.5566 22.9289 36.1424 23.5147L44.6277 32L36.1424 40.4853C35.5566 41.0711 35.5566 42.0208 36.1424 42.6066C36.7282 43.1924 37.678 43.1924 38.2637 42.6066L47.8097 33.0607ZM19.749 33.5L46.749 33.5L46.749 30.5L19.749 30.5L19.749 33.5Z"
        fill="black"
      />
    </svg>
  );
};
